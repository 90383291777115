<template>
  <v-app-bar app flat>
    <v-app-bar-nav-icon class="hidden-md-and-up navbar" @click="toggleDrawer" />

    <v-container class="mx-auto py-0">
      <v-row align="center">
        <v-img
          :src="require('@/assets/images/logo.gif')"
          class="mr-6 logo"
          contain
          height="300"
          width="300"
          max-width="300"
          @click="onLogoClick()"
        />

        <div style="display: flex; align-items: center;">
          <!-- Iterate over links -->
          <div v-for="(link, i) in links" :key="i">
            <!-- Check if the link has children -->
            <v-menu v-if="link.children" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="hidden-sm-and-down" text>
                  {{ link.text }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(child, index) in link.children"
                  :key="`child-${index}`"
                  @click="onChildClick(child.href)"
                >
                  {{ child.text }}
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- Render as a regular button if there are no children -->
            <v-btn v-else v-bind="link" class="hidden-sm-and-down" text>
              {{ link.text }}
            </v-btn>
          </div>
        </div>
        <v-spacer />
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreAppBar",

  computed: {
    ...mapGetters(["links"]),
  },

  methods: {
    ...mapMutations(["toggleDrawer"]),

    onLogoClick() {
      if (this.$route.name === "home") {
        this.$vuetify.goTo(0);
      } else {
        this.$router.push("/#!");
      }
    },
    
    onChildClick(href) {
      // Handle navigation for child menu items
      this.$router.push(href);
    },
  },
};
</script>
<style scoped>
.logo {
  margin-top: 70px;
}

@media only screen and (max-width: 600px) {
  .logo {
    display: none;
  }
}
</style>
